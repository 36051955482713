import * as React from "react"
import "../App.scss";
import Section from "../components/Section/Section";
import Row from "../components/Row/Row";
import Col from "../components/Col/Col";
import CtaBar from "../components/CtaBar/CtaBar";
import Review from "../components/Review/Review";
import Reviews from "../components/Reviews/Reviews";
import Nav from "../components/Nav/Nav";
import Footer from "../components/Footer/Footer";
import Button from "../components/Button/Button";
import video from "../img/video.mp4"
import imageQuestion1 from "../img/q1.jpg"
import blurText from "../img/blurText.png"
import circle from "../img/circle.svg"

import review1 from "../img/review1.jpg"
import review2 from "../img/review2.jpg"
import review3 from "../img/review3.jpg"
import review4 from "../img/review4.jpg"
import review5 from "../img/review5.jpg"
import review6 from "../img/review6.jpg"
import review7 from "../img/review7.jpg"
import review8 from "../img/review8.jpg"
import review9 from "../img/review9.jpg"
import review10 from "../img/review10.jpg"
import review11 from "../img/review11.jpg"
import review12 from "../img/review12.jpg"

import course from "../img/course.png"
import courseMindSystems from "../img/courseMindSystems.png"
import courseCompanyCoaching from "../img/companyCoaching.png"
import courseMentorships from "../img/courseMentorships.png"
import courseMasterminds from "../img/courseMasterminds.png"

import playIcon from "../img/playIcon.svg"
import heroImage from "../img/hero_team.jpg"
import mindtrepreneurStrip from "../img/mindtrepreneur_strip.jpg"


// data

{/*

const pos = { x : 0, y : 0 };

const saveCursorPosition = function(x, y) {
    pos.x = (x / window.innerWidth).toFixed(2);
    pos.y = (y / window.innerHeight).toFixed(2);
    document.documentElement.style.setProperty('--x', pos.x);
    document.documentElement.style.setProperty('--y', pos.y);
}

document.addEventListener('mousemove', e => { saveCursorPosition(e.clientX, e.clientY); })

*/}

{/*

const one = document.getElementById('hero');

window.addEventListener('scroll', () => {
  let scrollY = window.scrollY;
  document.documentElement.style.setProperty('--scrollPosition', scrollY);
})

*/}

// markup
const Testimonials = () => {
  return (
    <main>
      <Nav />
      <Section className="hero heroLow" id="hero">
        <Row>

          <Col size="50">
            <h1>How can we help</h1>
          </Col>
          <Col size="50" className="hero__button">
            <p>
              We believe for an entrepreneur to live their best life possible, they must focus
              on growing themselves. Our mission is to help entrepreneurs transform their live so they can live their best, most fulfilled life.
            </p>
          </Col>
        </Row>
      </Section>

      <Section className="programs">

        <div className="programs__item">
          <img src={course} className="programs__img" />
          <div>
            <h3>7-Days to 7-Figures</h3>
              <p>
                7-Days to 7-Figures is a new program where you unlock 7-Secret Mindset Principles to gain maximum
                clarity, feel unlimited motivation, build unshakable confidence, and form identity shifting beliefs
                so you can finally master all areas of your life and business.
              </p>
              <Button type="secondary" link="https://www.7days7figures.com/join" text="Join now"/>
          </div>
        </div>

        <div className="programs__item">
          <img src={courseMindSystems} className="programs__img" />
          <div>
          <h3>Mind Systems Pro</h3>
          <p>
            Mind-Systems Pro is a 90-day program designed for aspiring entrepreneurs or entrepreneurs
            who have begun their journey. They know the actions they need to take to start or grow their
            business but find themselves getting in their own way. The main objective for Mind-Systems Pro
            is to help you master your discipline, consistency and focus and build them as habits to allow
            you to make it automatic and effortless to succeed in business.
          </p>
          <Button type="secondary" link="https://go.mindtrepreneur.com/book" text="Apply now"/>
          </div>

        </div>

        <div className="programs__item">
            <img src={courseMentorships} className="programs__img" />
          <div>
          <h3>Mindtrepreneur Mentorship</h3>
          <p>
            Mindtrepreneur Mentorship is for advanced entrepreneurs who are seeing success and making progress
            but they feel as though they are not reaching their full potential, or they feel that they are getting
            in their own way from living their most successful life in all areas.
          </p>
          <Button type="secondary" link="https://go.mindtrepreneur.com/book" text="By invite only"/>
          </div>

        </div>

        <div className="programs__item">
            <img src={courseMasterminds} className="programs__img" />
          <div>
          <h3>Mindtrepreneur Masterminds</h3>
          <p>
            Mindtrepreneur Mastermind is exclusively by invite only where we host in person mastermind around the world,
            where we all collectively come together to grow and level up our mindsets and businesses.
          </p>
          <Button type="secondary" link="/masterminds" text="By invite only"/>
          </div>

        </div>

        <div className="programs__item">
            <img src={courseCompanyCoaching} className="programs__img" />
          <div>
          <h3>Company Coaching</h3>
          <p>
            Want to build a strong and powerful team, that isn’t wasting time, energy, or money? Want to create a
            culture of winners? Vash & Jon fly all over the world to train companies to dominate their market.
          </p>
          <Button type="secondary" link="mailto:support@mindtrepreneur.com" text="Contact us"/>
          </div>

        </div>

      </Section>


      <Footer/>

    </main>
  )
}

export default Testimonials
